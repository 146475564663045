import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEdit } from "@fortawesome/free-solid-svg-icons";

ACL2 is:

- _A Computational Logic_: A complex mechanized theorem prover that can reason
  about code written in...
- _Applicative Common Lisp_: A significantly restricted subset of Common Lisp
  that doesn't allow for variables to be modified in place.

ACL2 uses a subset of the Lisp programming language. Lisp syntax is one of the
simplest ones of any programming language. However, it is different from most
other languages, so let's take a moment to get acquainted with it.

Click the <FontAwesomeIcon icon={faArrowRight} /> button above to get started
with the tutorial, or click the <FontAwesomeIcon icon={faEdit} /> button to
switch to the Proof Pad IDE.
