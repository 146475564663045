For our next set of theorems, try writing a function definition yourself. We
want a function called `sum` that adds up all of the elements in a list. So it
should work like this:

    (sum (list 8 6 7 5 3 0 9)) ; 38

Keep in mind some functions and predicates we defined earlier

    (endp xs) ; Tests if there are any elements in xs
    (first xs) ; Gets the first element of xs
    (rest xs) ; A list of all the elements except the first

Go to the next section if you need a hint.
