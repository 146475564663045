## 1. Lisp

First of all, every operation in Lisp is a function\* -- even very basic
mathematical operations, such as addition. Secondly, functions are called in a
different way than they are in mathematics or in most other languages. While in
most languages you would write `f(x, y)`, in Lisp, you write `(f x y)`.

The addition function is called `+`. Try adding two numbers now:

    (+ 123 456)

> TIP: You can click on any code you see in this tutorial to insert it at the
> prompt.

\* Technically, this is only partially true, but it's good enough for us for
now.
